
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    pBtnList: {
      default: null as any
    },
    pInputIndex: {},
    pInputObj: {
      default: null as any
    },
    pToggleYn: {},
    pChangeInput: Function,
    pBtnSize: {
      default: null as any
    }
  },
  data () {
    return {
      selectedBtnIndex: 0
    }
  },
  watch: {
    pInputObj: {
      immediate: true,
      handler (val) {
        if (val.val !== undefined && val.val !== null && val.val !== '') {
          if (this.pBtnList && this.pBtnList.length > 0) {
            const idx = this.pBtnList.findIndex((item : any) => item.value === val.val)
            if (idx !== -1) {
              this.selectedBtnIndex = idx
            }
          }
        }
      },
      deep: true
    }
  },
  methods: {
    changeBtnEvnt (index : number, btn: any) {
      this.selectedBtnIndex = index
      const inputObj = this.pInputObj
      inputObj.val = this.pBtnList[this.selectedBtnIndex].value
      if (this.pChangeInput) {
        this.pChangeInput(inputObj, this.pInputIndex)
      }
      if (btn.onclickCallBack) {
        btn.onclickCallBack(btn.value)
      }
    }
  }
})
