
import { defineComponent } from "vue";
import { authData } from "@/utils/authData";
import { VueDaumPostcodeSearchResult } from "vue-daum-postcode";


import MZDonaListTableHeader from '@/components/donalist/MZDonaListTableHeader.vue'
import MZDonaListTable from '@/components/donalist/MZDonaListTable.vue'
import MZSearchJusoPop from '@/components/popup/MZSearchJusoPop.vue'
import MZNameCardTab from '@/components/namecard/MZNameCardTab.vue'
import MZNameCardLikeList from "../namecard/MZNameCardLikeList.vue";
import MZNameCardMemoList from "../namecard/MZNameCardMemoList.vue";

import MZProfiePicChangePop from '@/components/popup/MZProfiePicChangePop.vue'
import { commonValiValue } from '../../utils/MZValidation';

export default defineComponent({
	props: {
    pMoveToComment: Function,
    pclosePop: Function,
    pClosePop: Function,
    pUserInfo: Object as any,
    pMogData: Object as any,
		pFuncGetMogDetail: Function,
    pOpenDonaThankPop: Function,
    pFuncGetUserDetail: Function
  },
  components: {
    MZDonaListTableHeader,
    MZDonaListTable,
    MZSearchJusoPop,
    MZNameCardTab,
    MZNameCardLikeList,
    MZNameCardMemoList,
    MZProfiePicChangePop
  },
  computed: {
    GE_USER () {
      const savedUserInfo = authData.getUserInfo()
      return savedUserInfo
    }
  },
  data() {
    return {
      mBtnData:  { btnText: '편집', onClickFn: this.toggleEdit, btnType: 'noBorder', pointYn: true },
      mCloseNameCardPop: false,
      mCheerPopShowYn: false,
      result: null as VueDaumPostcodeSearchResult | null,
      btnData: [
        { btnText: '주소찾기', onClickFn: this.openSearchJuso, btnType: 'noBorder', pointYn: true },
        { btnText: '초기화', onClickFn: this.resetJuso, btnType: 'noBorder', pointYn: false }
      ],
      mEditBtnData: [
        { btnText: '저장', onClickFn: this.saveUser, btnType: 'noBorder', pointYn: true },
        { btnText: '취소', onClickFn: this.toggleEdit, btnType: 'noBorder', pointYn: false }
      ],
      mShowSearchJusoYn: false,
      mSelectedAddress: {} as any,
      mGibuList: Object as any,
      mConfirmTitle: '알림',
      mConfirmText: '',
      mEditInfo: [
        { valiYn: true, eText: '이름을 적어주세요.', placeholder: '이름', type: 'T', targetName: '이름', value: null as any, valiBase: [{ type: 'NULL' }] },
        { valiYn: false, eText: '소개글을 적어주세요.', placeholder: '소개글을 적어주세요', type: 'T', targetName: '내 소개', value: null as any, valiBase: [{ type: 'NULL' }] }
      ],
      mCommentWrite: { valiYn: true, eText: '상세주소를 입력해주세요', placeholder: '상세주소', type: 'T', targetName: '상세주소', value: null as any, valiBase: [{ type: 'NULL' }] },
      mDetailAddress: 
        {valiYn: true, eText: '소개글을 적어주세요.', placeholder: '상세 주소 입력란', type: 'T', targetName: '내 소개', value: null as any, valiBase: [{ type: 'NULL' }] },
      mSelectedTab: 'G',
      mTabList: [
        { type: 'G', tabname: '구매목록', clickedYn: false },
        { type: 'D', tabname: '응원', clickedYn: false },
        { type: 'J', tabname: '좋아요', clickedYn: false }
      ],
      mPageInfo: {},
      mCurrentPage: 0,
      mEditYn: false,
      mConfirmPopShowYn: false,
      mPicChangePopShowYn: false,
      PopData: {
        title: "마이페이지",
        closeFn: this.pclosePop,
        size: { w: window.innerWidth / 10 * 9, h: window.innerHeight / 10 * 9 },
        btnList: [{ btnText: "닫기", onClickFn: this.pclosePop, btnType: 'noBorder', pointYn: true }],
        isFull: true,
        isBig: true
      }
    }
  },
  watch: {
    PopData: {
      handler (val) {
        alert(true)
      },
      deep: true
    },
    mEditInfo: {
      handler (val) {
        if (val[1].value != null) {
          if (val[1].value.length > 100) {
            let result = val[1].value.substring(0, 100)
            val[1].value = result
          }
        }
      },
      deep: true
    }
  },
  methods: {
    closePicChangePop () {
      this.mPicChangePopShowYn = false
    },
    openPicChangePop () {
      this.mPicChangePopShowYn = true
    },
    closeConfirmPop () {
      this.mConfirmPopShowYn = false
    },
    resetJuso () {
      this.mSelectedAddress.zonecode = null
      this.mSelectedAddress.address = null
      this.mCommentWrite.value = null
    },
    async saveUser () {
      const valiResult = commonValiValue(this.mEditInfo)
      if (valiResult.result) {
        const param = {
          user: {
            userKey: this.GE_USER.userKey,
            userDispName: 'KO$^$' + this.mEditInfo[0].value,
            userDescription: this.mEditInfo[1].value,
            userJuso: ''
          }
        }
        if (this.mSelectedAddress.zonecode) {
          param.user.userJuso = `${this.mSelectedAddress.zonecode}$^$${this.mSelectedAddress.address}$#$${this.mCommentWrite.value}`
        }
        const result = await this.$gHTTPfunctions.updateUser(param)
        if (result.data.result) {
          await this.pFuncGetUserDetail()
          this.convertJuso()
          this.mEditInfo[0].value = this.GE_USER.userDispName
          this.mEditInfo[1].value = this.GE_USER.userDescription
          this.mEditYn = false
        }
      } else {
        this.mConfirmText = valiResult.resultMsg
        this.mConfirmPopShowYn = true
      }
    },
    toggleEdit () {
      if (this.mEditYn) {
        this.mEditInfo[0].value = this.GE_USER.userDispName
        this.mEditInfo[1].value = this.GE_USER.userDescription
      }
      this.mEditYn = !this.mEditYn
    },
    convertJuso (juso: string) {
      if (!juso || juso === '') {
        this.resetJuso()
        return '등록된 주소가 없습니다'
      }
      const zipCode = juso.split('$^$')[0]
      this.mSelectedAddress.zonecode = zipCode
      const juso1 = juso.split('$^$')[1].split('$#$')[0]
      this.mSelectedAddress.address = juso1
      const juso2 = juso.split('$^$')[1].split('$#$')[1]
      this.mCommentWrite.value = juso2
      return `(${zipCode}) ${juso1}, ${juso2}`
    },
    changeSelectedTab (type: string) {
      this.mCurrentPage = 0
      this.mSelectedTab = type
      if (type === 'G') {
        this.getGibuList()
      } else if (type === 'J') {
        const likeList:any = this.$refs.likeList
        if (likeList) {
          likeList.resetPage()
        }
        this.getLikeList(0)
      } else if (type === 'D') {
        const memoRef:any = this.$refs.memoList
        if (memoRef) {
          memoRef.resetPage()
        }
        this.funcGetMemoList(0)
      }
    },
    openSearchJuso () {
      this.mShowSearchJusoYn = true
    },
    closeSearchJuso (value: any) {
      this.mShowSearchJusoYn = false
      this.mSelectedAddress = value
    },
    onSearch(result: VueDaumPostcodeSearchResult) {
      this.$refs.daumPostcode.open();
    },
    handleAddressSelected(address: string) {
      this.mSelectedAddress = address;
    },
    goMyGibuList () {
      this.$router.push('/donalist')
      this.pclosePop()
    },
    moveToMogDetail () {
      this.$router.push({path: '/dona/100'})
      this.pclosePop()
    },
    async getGibuList() {
      const param = {
        gibuUserKey: this.GE_USER.userKey,
        pageSize: 5,
        offsetInt: 0
      }
      const result = await this.$gHTTPfunctions.getGibuList(param);
      if (result.data.result && result.data.gibu)
      this.mPageInfo = result.data.gibu
      this.mGibuList = result.data.gibu.content
    },
    async getLikeList (offsetInt: number) {
      const param = {
        targetKind: 'M',
        doType: 'LI',
        userKey: this.GE_USER.userKey,
        pageSize: 5,
        offsetInt: offsetInt
      }
      const doResult = await this.$gHTTPfunctions.getUserDoList(param);
      this.mPageInfo = doResult.data.userDo
      this.mGibuList = doResult.data.userDo.content
      this.mCurrentPage = offsetInt
    },
    async funcGetMemoList (offsetInt: number) {
      let param = {
        pageSize: 5,
        pMemoYn: false,
        creUserKey: this.GE_USER.userKey,
        targetKind: 'M',
        offsetInt: offsetInt
      }
      const result = await this.$gHTTPfunctions.getMemoList(param);
      if (result && result.data) {
        this.mPageInfo = result.data.memo
        this.mGibuList = result.data.memo.content
        this.mCurrentPage = offsetInt
      }
    }
  },
  mounted () {
    this.convertJuso()
    this.mEditInfo[0].value = this.GE_USER.userDispName
    this.mEditInfo[1].value = this.GE_USER.userDescription
  },
  beforeMount() {
    this.getGibuList();
  }
})
