
import { defineComponent } from 'vue'

export default defineComponent({
  data () {
    return {
      mTabList: [
        { type: 'G', tabname: '구매목록', clickedYn: false },
        { type: 'D', tabname: '댓글', clickedYn: false },
        { type: 'J', tabname: '응원목록', clickedYn: false }
      ],
      mSelectedTab: ''
    }
  },
  props: {
    pTabList: Array,
    pChangeSelectedTab: Function ,
    pDefaultTab: String
  },
  mounted () {
    this.mSelectedTab = this.pDefaultTab
  },
  methods: {
    goToNameCardDetail (type: string) {
      this.pChangeSelectedTab(type)
      this.mSelectedTab = type
    },
  }
})
