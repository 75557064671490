import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '../store'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Main Page',
    component: () => import('@/views/MZMainView.vue'),
  },
  {
    path: '/intro',
    name: 'intro',
    component: () => import('@/views/MZIntro.vue'),
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('@/views/MZErrorPage.vue'),
  },
  {
    path: '/policy',
    name: 'policy',
    component: () => import('@/views/MZPolicy.vue'),
  },
  {
    path: '/policyArea',
    name: 'PolicyArea',
    component: () => import('@/views/policy/MZPolicyArea.vue'), // 정책 페이지
  },
  {
    path: '/policyE',
    name: 'PolicyE',
    component: () => import('@/views/policy/MZPolicyE.vue'), // (1) 이용약관
  },
  {
    path: '/policyG',
    name: 'PolicyG',
    component: () => import('@/views/policy/MZPolicyG.vue'), // (2) 개인정보 처리방침
  },
  {
    path: '/policyB',
    name: 'PolicyB',
    component: () => import('@/views/policy/MZPolicyB.vue'), // (3) 배송 및 반품 약관
  },
  {
    path: '/policyJ',
    name: 'PolicyJ',
    component: () => import('@/views/policy/MZPolicyJ.vue'), // (4) 전자금융거래 약관
  },
  {
    path: '/policyS',
    name: 'PolicyS',
    component: () => import('@/views/policy/MZPolicyS.vue'), // (5) 제 3자 마케팅 동의
  },
  {
    path: '/donapay',
    name: 'MZDonationDetaiil',
    component: () =>
      import('@/components/payDetail/MZDonationDetailForPay.vue'),
    beforeEnter: (to, from, next) => {
      const user = store.getters['MZ_USER/GE_USER']
      if (!user.userKey) next({ path: '/' })
      else next()
    },
  },
  {
    path: '/donathank',
    name: 'donation thanks card',
    component: () => import('@/components/popup/MZDonaThankPop.vue'),
  },
  {
    path: '/dona/:mogKey',
    name: 'Donation Detail Desc.',
    component: () => import('@/views/MZDonationInDetail.vue'),
    beforeEnter: (to, from, next) => {
      const user = store.getters['MZ_USER/GE_USER']
      if (user.userKey && !user.certiDate) next({ path: '/' })
      else next()
    },
  },
  {
    path: '/donalist',
    name: 'My Donation List',
    component: () => import('@/views/MZMyGibuList.vue'),
    beforeEnter: (to, from, next) => {
      const user = store.getters['MZ_USER/GE_USER']
      if (!user.userKey) next({ path: '/' })
      else next()
    },
  },
  {
    path: '/refund/:gibuKey',
    name: 'Refund Page',
    component: () => import('@/views/MZRefund.vue'),
  },
  {
    path: '/develop',
    name: 'develop',
    component: () => import('@/views/MZDeveloperPage.vue'),
  },
  {
    path: '/errorPage',
    name: 'errorPage',
    component: () => import('@/views/MZErrorPage.vue'),
  },
  // { path: '/:catchAll(.*)', redirect: '/errorPage' }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 항상 맨 위로 스크롤
    return { top: 0 };
  },
});

export default router;
