// T&F: true/false
// NULL
// MIN
// MAX
interface IVALUE {
    valiYn: boolean,
    targetName: string
    value: any,
    eText: string,
    valiBase?: {type: string, baseVal: any}[] | undefined
}
export function commonValiValue (valList : IVALUE[] | any) {
  const returnObj = { result: true, resultMsg: 'OK', resultCode: '' } as {result: boolean, resultMsg: string, resultCode: string}

  try {
    for (let i = 0; i < valList.length; i++) {
      const valObj = valList[i]
      if (!valObj.valiYn) continue
      if (valObj.valiBase) {
        for (let t = 0; t < valObj.valiBase.length; t++) {
          let result = true
          console.log('valObj.valiBase[i]')
          console.log(valObj.valiBase[i])
          const valiBase = valObj.valiBase[t]
          if (valiBase.type === 'T&F') {
            result = checkBooleanVal(valiBase.baseVal, valObj.value)
          } else if (valiBase.type === 'NULL') {
            result = checkNullVal(valObj.value)
          } else if (valiBase.type === 'MAX') {
            result = checkMaxLength(valiBase.baseVal, valObj.value)
          } else if (valiBase.type === 'MIN') {
            result = checkMinLength(valiBase.baseVal, valObj.value)
          } else if (valiBase.type === 'NUM') {
            result = checkNumberOnly(valObj.value)
          } else if (valiBase.type === 'PER') {
            result = checkPercentVal(valObj.value)
          }
          if (result === false) {
            returnObj.result = result
            returnObj.resultCode = 'FAIL: ' + valiBase.type + '$#$' + valObj.targetName
            returnObj.resultMsg = valObj.eText
            return returnObj
          }
        }
      }
    }
  } catch (error) {
    console.log(error)
  }
  return returnObj
}
/* function makeValiErrorText (errorText: string) {
  const eTextList : string[] = errorText.split('$#$')
  const eValiType: string = eTextList[0]
  const eValiDispName : string = eTextList[1]
  let returnString = eValiDispName
  if (eValiType === 'NULL') {
    returnString += '(이)가 입력되지 않았습니다'
  } else if (eValiType === 'T&F') {
    returnString += '는 반드시 선택되어야 합니다.'
  }  else if (eValiType === 'MAX') {
    returnString += '는 4글자 '
  }
} */
function checkNumberOnly (num : any) {
  let returnData = true
  const regExp = /^[0-9]*$/
  if (!regExp.test(num)) {
    // alert('숫자만 입력 가능합니다. 다시 입력해주세요.')
    returnData = false
  }
  return returnData
}

function checkMaxLength (baseBal : number, inValue: any) : boolean {
  let returnData = true
  if (typeof inValue === 'number') {
    if (inValue > baseBal) {
      returnData = false
    }
  } else {
    const valLength = inValue.length
    if (valLength > baseBal) {
      returnData = false
    }
  }
  return returnData
}
function checkMinLength (baseBal : number, inValue: any) : boolean {
  let returnData = true
  if (typeof inValue === 'number') {
    if (inValue < baseBal) {
      returnData = false
    }
  } else {
    const valLength = inValue.length
    if (valLength < baseBal) {
      returnData = false
    }
  }
  return returnData
}
function checkBooleanVal (baseBal : any, inValue: any) : boolean {
  let returnData = false
  if (inValue === baseBal) {
    returnData = true
  }
  return returnData
}
function checkNullVal (inValue: any) : boolean {
  let returnData = true
  let targetVal = inValue
  try {
    if (isNaN(targetVal)) {
      targetVal = inValue.trim()
    }
  } catch (error) {
    returnData = false
    console.log(error)
  }
  if (!targetVal) {
    returnData = false
  }
  return returnData
}
function checkPercentVal (inValue: any) {
  let returnData = true
  let targetVal = Number(inValue)
  try {
    targetVal = inValue.trim()
  } catch (error) {
    console.log(error)
  }
  if (targetVal < 0 || targetVal > 100) {
    returnData = false
  }
  return returnData
}
/*
// 정수만 입력받는 함수
function isNumeric (obj, val) {
  const regNumber = /^[0-9]*$/
  if (!regNumber.test(val)) {
    alert('숫자(정수)만 입력가능합니다.')
    obj.value = ''
    obj.focus()
  }
}

// 소수점 N번째 까지만 입력받는 함수
function fPointCheck (f, val, point) { // f 는 객체, val은 변수값, point는 소수점 자리수
  const t = val
  if (t.indexOf('.') != -1) {
    const t_length = t.substring(t.indexOf('.') + 1)
    if (point == 1) {
      if (t_length.length > 1) {
        alert('소수 첫째자리까지만 입력됩니다.')
        f.value = ''
        f.focus()
        return false
      } else {
        return true
      }
    } else if (point == 2) {
      if (t_length.length > 2) {
        alert('소수 둘째자리까지만 입력됩니다.')
        f.value = ''
        f.focus()
        return false
      } else {
        return true
      }
    }
  }
  return true
}
*/
