import dayjs from 'dayjs'

const changeDateFormat = (date : number | Date, mustTimeShowYn: boolean, onlyShowYMDYN: boolean, onlyShowMMDD: boolean): string => {
        // var compareDate = new Date(Number(date))
        const compareDate = new Date(date)
        const toDate = new Date()
        let format = ''

        if (onlyShowYMDYN) { // 년원일로 무조건 볼 수 있게
          format = 'YYYY/MM/DD'
          return dayjs(compareDate).format(format)
        } else if (onlyShowMMDD) {
          format = 'MM/DD'
          return dayjs(compareDate).format(format)
        }
        if (dayjs(compareDate).format('YYYY') === dayjs(toDate).format('YYYY')) {
          if (dayjs(compareDate).format('MM') === dayjs(toDate).format('MM')) {
            if (dayjs(compareDate).format('DD') === dayjs(toDate).format('DD')) {
              // 년도 월 일 이 같으면 만든 시간, 분
              format = 'HH:mm'
              // format = 'HH시 mm분'
            } else {
              // 같은 년도, 월이 같으면
              /* format = 'MM/DD HH:mm' */
              format = 'MM/DD'
              // format = 'MM월 DD일'
            }
          } else {
            // 년도만 같으면
            format = 'MM/DD'
            // format = 'MM월 DD일'
          }
        } else {
          format = 'YYYY/MM/DD'
        }
        // format = 'YYYY/MM/DD'
        if (mustTimeShowYn) format = 'YYYY-MM-DD HH:mm'
        // eslint-disable-next-line no-dupe-else-if
        else if (mustTimeShowYn && format !== 'HH:mm') format += ' HH:mm'
        // return dayjs(compareDate).add(9, 'hour').format(format)
        return dayjs(compareDate).format(format)
}

export default changeDateFormat