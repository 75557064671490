
export default {
  props: {
    pPageInfo: Object,
    pGetListFunction: Function,
    pMoveYn: Boolean
  },
  data () {
    return {
      mCurrentPage: 1,
      mPageInput: [{ valiYn: true, eText: '올바른 페이지를 입력해주세요', type: 'N', targetName: '페이지', value: 1, valiBase: [{ type: 'MIN', baseVal: 1 }, { type: 'MAX', baseVal: this.pPageInfo.totalPages }, { type: 'NULL' }, { type: 'NUM' }] }],
      mMoverBtnData: { btnText: '이동', onClickFn: this.getListDirect, btnType: 'noBorder', pointYn: false },
      mAlertPopInfo: {
        title: '',
        closeFn: this.closeAlertPop,
        size: { w: 300, h: 250 },
        btnList: [{ btnText: "닫기", onClickFn: this.closeAlertPop, btnType: 'noBorder', pointYn: true }],
        isFull: false,
        isConfirm: true,
        noHeader: true
      },
      mConfirmPopShowYn: '',
      mShowAlertPopYn: false,
      mConfirmTitle: '알림',
      mConfirmText: ''
    }
  },
  methods: {
    resetCurrentPage () {
      this.mCurrentPage = 1
      this.mPageInput[0].value = 1
    },
    closeConfirmPop () {
      this.mConfirmPopShowYn = false
    },
    openAlertPop (text: string, title:string) {
      this.mConfirmText = text
      this.mConfirmTitle = title
      this.mConfirmPopShowYn = true
    },
    getList (type: string) {
      if (type === '+') {
        this.mCurrentPage++
        this.pGetListFunction(this.mCurrentPage - 1)
      } else if (type === '-') {
        this.mCurrentPage--
        this.pGetListFunction(this.mCurrentPage - 1)
      }
    },
    getListDirect () {
      if (this.mPageInput[0].value > 0 && this.mPageInput[0].value <= this.pPageInfo.totalPages) {
        this.pGetListFunction(this.mPageInput[0].value - 1)
        this.mCurrentPage = this.mPageInput[0].value
      } else {
        this.openAlertPop('올바른 페이지를 입력해주세요.', '페이지 이동')
      }
    }
  }
}
