
import { defineComponent } from "vue";
import MZDonaThankPop from "../popup/MZDonaThankPop.vue";

export default defineComponent({
  props: {
    pclosePop: Function,
    pGibuListData: Object as any,
    pMogData: Object as any,
    pCurrentPage: Number,
    pIndex: Number,
    pOpenDonaThankPop: Function,
    pNameCardYn: Boolean,
    pLikeYn: Boolean
  },
  components: {
    MZDonaThankPop,
  },
  computed: {
    mRowIndex () {
      if (this.pCurrentPage !== undefined && this.pIndex !== undefined) {
        return ( this.pCurrentPage * 5 ) + ( this.pIndex +1 )
      } else {
        return '-'
      }
    }
  },
  data() {
    return {
      mDonaThankPopShowYn: false,
      mBtnData: { btnText: '구매 취소', onClickFn: this.goToRefund, btnType: 'noBorder', pointYn: false }
    };
  },
  methods: {
    openDonaThankPop () {
      if (this.pLikeYn) {
        this.$router.push({path: `/dona/${this.pMogData.targetKey}`})
        this.pclosePop()
      } else if (this.pOpenDonaThankPop) {
        this.pOpenDonaThankPop(this.pGibuListData)
      }
    },
    closeMZDonaThankPop () {
      this.mDonaThankPopShowYn = false;
    },
    goToRefund () {
      if (this.pGibuListData && this.pGibuListData.gibuKey) {
        const key = this.pGibuListData.gibuKey;
        this.$router.push(`/refund/${key}`)
      }
    }
  },
});
