import axios from 'axios'
import router from '@/router'

function showAxiosLoading (showYn : boolean) {
  const loadingCompo = document.getElementById('axiosShadow')
  if (showYn) {
    /*  if (loadingCompo.style.display === 'flex') */
    if (loadingCompo) loadingCompo.style.display = 'flex'
    // setTimeout(() => {
    //   if (loadingCompo) loadingCompo.style.display = 'none'
    // }, 3000)
  } else {
    if (loadingCompo) loadingCompo.style.display = 'none'
  }
}

const HEADERS = {
  'Content-type': 'application/json; text/html; charset=utf-8;',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET,POST,PATCH,PUT,DELETE,OPTIONS',
  'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
  'Access-Control-Allow-Credentials': true,
};

const HTTPfunctions = {
  loginCheck: async (param: object): Promise<any> => {
    try {
      showAxiosLoading(true)
      const res = await axios.post('https://mzoin.com/service/mz.loginCheck', param, {
        headers: HEADERS,
      });
      return res;
    } catch (error: any) {
      console.log(error);
    } finally {
      showAxiosLoading(false)
      // commonMethods.showAxiosLoading(false)
    }
  },
  getUserDetail: async ( param: object ): Promise<any> => {
    try {
      console.log('============/mz.getUserDetail : param============ ')
      console.log(param)
      showAxiosLoading(true)
      const res = await axios.post('https://mzoin.com/service/mz.getUserDetail', param, { headers: HEADERS })
      console.log('===========/mz.getUserDetail : result==============')
      console.log(res)
      return(res)
    } catch(error: any) {
      if (error.response && error.response.status) {
        const errorRoute = { name: 'error', query: { errorStatus: error.response.status } }
        router.push(errorRoute)
      } else {
        router.push('/error')
      }
      console.log(error);
    } finally {
    showAxiosLoading(false)
    // commonMethods.showAxiosLoading(false)
    }
  },
  getMogList: async (param: object): Promise<any> => {
    try {
      console.log('==========/mz.getMogList : param==========')
      console.log(param)
      showAxiosLoading(true)
      const res = await axios.post('https://mzoin.com/service/mz.getMogList', param, { headers: HEADERS })
      console.log('==========/mz.getMogList : result==========')
      console.log(res)
      return res
    } catch (error: any) {
      if (error.response && error.response.status) {
        const errorRoute = { name: 'error', query: { errorStatus: error.response.status } }
        router.push(errorRoute)
      } else {
        router.push('/error')
      }
      console.log(error);
    } finally {
    showAxiosLoading(false)
    // commonMethods.showAxiosLoading(false)
    }
  },
  getMogDetail: async (param: object): Promise<any> => {
    try {
      console.log('============/mz.getMogDetail : param============ ')
      console.log(param)
      showAxiosLoading(true)
      const res = await axios.post('https://mzoin.com/service/mz.getMogDetail', param, {
        headers: HEADERS,
      });
      console.log('===========/mz.getMogDetail : result==============')
      console.log(res)
      return res;
    } catch (error: any) {
      if (error.response && error.response.status) {
        const errorRoute = { name: 'error', query: { errorStatus: error.response.status } }
        router.push(errorRoute)
      } else {
        router.push('/error')
      }
      console.log(error);
    } finally {
    showAxiosLoading(false)
    // commonMethods.showAxiosLoading(false)
    }
  },
  saveMog: async (param: object): Promise<any> => {
    try {
      console.log('============/mz.saveMog : param============ ')
      console.log(param)
      showAxiosLoading(true)
      const res = await axios.post('https://mzoin.com/service/mz.saveMog', param, { headers: HEADERS });
      console.log('===========/mz.saveMog : result==============')
      console.log(res)
      return res;
    } catch (error: any) {
      if (error.response && error.response.status) {
        const errorRoute = { name: 'error', query: { errorStatus: error.response.status } }
        router.push(errorRoute)
      } else {
        router.push('/error')
      }
      console.log(error);
    } finally {
    showAxiosLoading(false)
    // commonMethods.showAxiosLoading(false)
    }
  },
  editMog: async (param: object): Promise<any> => {
    try {
      console.log('============/mz.saveMog : param============ ')
      console.log(param)
      showAxiosLoading(true)
      const res = await axios.post('https://mzoin.com/service/mz.saveMog', param, { headers: HEADERS });
      console.log('===========/mz.saveMog : result==============')
      console.log(res)
      return res;
    } catch (error: any) {
      if (error.response && error.response.status) {
        const errorRoute = { name: 'error', query: { errorStatus: error.response.status } }
        router.push(errorRoute)
      } else {
        router.push('/error')
      }
      console.log(error);
    } finally {
    showAxiosLoading(false)
    // commonMethods.showAxiosLoading(false)
    }
  },
  deleteMog: async (param: object): Promise<any> => {
    try {
      console.log('============/mz.deleteMog : param============ ')
      console.log(param)
      showAxiosLoading(true)
      const res = await axios.post('https://mzoin.com/service/mz.deleteMog', param, {
        headers: HEADERS,
      });
      console.log('===========/mz.deleteMog : result==============')
      console.log(res)
      return res;
    } catch (error: any) {
      if (error.response && error.response.status) {
        const errorRoute = { name: 'error', query: { errorStatus: error.response.status } }
        router.push(errorRoute)
      } else {
        router.push('/error')
      }
      console.log(error);
    } finally {
    showAxiosLoading(false)
    // commonMethods.showAxiosLoading(false)
    }
  },
  saveGibu: async (param: object): Promise<any> => {
    try {
      console.log('============/mz.saveGibu : param============ ')
      console.log(param)
      showAxiosLoading(true)
      const res = await axios.post('https://mzoin.com/service/mz.saveGibu', param, { headers: HEADERS });
      console.log('===========/mz.saveGibu : result==============')
      console.log(res)
      return res;
    } catch (error: any) {
      if (error.response && error.response.status) {
        const errorRoute = { name: 'error', query: { errorStatus: error.response.status } }
        router.push(errorRoute)
      } else {
        router.push('/error')
      }
      console.log(error);
    } finally {
    showAxiosLoading(false)
    // commonMethods.showAxiosLoading(false)
    }
  },
  getGibuList: async (param: object): Promise<any> => {
    try {
      console.log('============/mz.getGibuList : param============ ')
      console.log(param)
      showAxiosLoading(true)
      const res = await axios.post('https://mzoin.com/service/mz.getGibuList', param, {
        headers: HEADERS,
      });
      console.log('===========/mz.getGibuList : result==============')
      console.log(res)
      return res;
    } catch (error: any) {
      if (error.response && error.response.status) {
        const errorRoute = { name: 'error', query: { errorStatus: error.response.status } }
        router.push(errorRoute)
      } else {
        router.push('/error')
      }
      console.log(error);
    } finally {
    showAxiosLoading(false)
    // commonMethods.showAxiosLoading(false)
    }
  },
  getMemoList: async (param: object): Promise<any> => {
    try {
      console.log('============/mz.getMemoList : param============ ')
      console.log(param)
      showAxiosLoading(true)
      const res = await axios.post('https://mzoin.com/service/mz.getMemoList', param, { headers: HEADERS });
      console.log('===========/mz.getMemoList : result==============')
      console.log(res)
      return res;
    } catch (error: any) {
      if (error.response && error.response.status) {
        const errorRoute = { name: 'error', query: { errorStatus: error.response.status } }
        router.push(errorRoute)
      } else {
        router.push('/error')
      }
      console.log(error);
    } finally {
    showAxiosLoading(false)
    // commonMethods.showAxiosLoading(false)
    }
  },
  saveMemo: async (param: object): Promise<any> => {
    try {
      console.log('============/mz.saveMemo : param============ ')
      console.log(param)
      showAxiosLoading(true)
      const res = await axios.post('https://mzoin.com/service/mz.saveMemo', param, { headers: HEADERS });
      console.log('===========/mz.saveMemo : result==============')
      console.log(res)
      return res;
    } catch (error: any) {
      if (error.response && error.response.status) {
        const errorRoute = { name: 'error', query: { errorStatus: error.response.status } }
        router.push(errorRoute)
      } else {
        router.push('/error')
      }
      console.log(error);
    } finally {
    showAxiosLoading(false)
    // commonMethods.showAxiosLoading(false)
    }
	},
	deleteMemo: async (param: object): Promise<any> => {
    try {
      console.log('============/mz.deleteMemo : param============ ')
      console.log(param)
      showAxiosLoading(true)
      const res = await axios.post('https://mzoin.com/service/mz.deleteMemo', param, { headers: HEADERS });
      console.log('===========/mz.deleteMemo : result==============')
      console.log(res)
      return res;
    } catch (error: any) {
      if (error.response && error.response.status) {
        const errorRoute = { name: 'error', query: { errorStatus: error.response.status } }
        router.push(errorRoute)
      } else {
        router.push('/error')
      }
      console.log(error);
    } finally {
    showAxiosLoading(false)
    // commonMethods.showAxiosLoading(false)
    }
  },
  saveUser: async ( param: object ): Promise<any> => {
    try {
      console.log('============/mz.saveUser : param============ ')
      console.log(param)
      showAxiosLoading(true)
      const res = await axios.post('https://mzoin.com/service/mz.saveUser', param, { headers: HEADERS })
      console.log('============/mz.saveUser : result============ ')
      console.log(res)
      return res
    } catch (error: any) {
      if (error.response && error.response.status) {
        const errorRoute = { name: 'error', query: { errorStatus: error.response.status } }
        router.push(errorRoute)
      } else {
        router.push('/error')
      }
      console.log(error);
    } finally {
      showAxiosLoading(false)
    }
  },
  saveUserDo: async (param: object): Promise<any> => {
    try {
      console.log('============/mz.saveUserDo : param============ ')
      console.log(param)
      showAxiosLoading(true)
      const res = await axios.post('https://mzoin.com/service/mz.saveUserDo', param, { headers: HEADERS });
      console.log('===========/mz.saveUserDo : result==============')
      console.log(res)
      return res;
    } catch (error: any) {
      if (error.response && error.response.status) {
        const errorRoute = { name: 'error', query: { errorStatus: error.response.status } }
        router.push(errorRoute)
      } else {
        router.push('/error')
      }
      console.log(error);
    } finally {
    showAxiosLoading(false)
    // commonMethods.showAxiosLoading(false)
    }
  },
  deleteUserDo: async (param: object): Promise<any> => {
    try {
      console.log('============/mz.deleteUserDo : param============ ')
      console.log(param)
      showAxiosLoading(true)
      const res = await axios.post('https://mzoin.com/service/mz.deleteUserDo', param, { headers: HEADERS });
      console.log('===========/mz.deleteUserDo : result==============')
      console.log(res)
      return res;
    } catch (error: any) {
      if (error.response && error.response.status) {
        const errorRoute = { name: 'error', query: { errorStatus: error.response.status } }
        router.push(errorRoute)
      } else {
        router.push('/error')
      }
      console.log(error);
    } finally {
    showAxiosLoading(false)
    // commonMethods.showAxiosLoading(false)
    }
  },
  createDeepLink: async (param: object): Promise<any> => {
    try {
      console.log('============/mz.getShortDynamicLink : param============ ')
      console.log(param)
      showAxiosLoading(true)
      const res = await axios.post('https://mzoin.com/service/mz.getShortDynamicLink', param, { headers: HEADERS });
      console.log('===========/mz.getShortDynamicLink : result==============')
      console.log(res)
      return res;
    } catch (error: any) {
      if (error.response && error.response.status) {
        const errorRoute = { name: 'error', query: { errorStatus: error.response.status } }
        router.push(errorRoute)
      } else {
        router.push('/error')
      }
      console.log(error);
    } finally {
    showAxiosLoading(false)
    // commonMethods.showAxiosLoading(false)
    }
  },
  updateUser: async (param: object): Promise<any> => {
    try {
      console.log('============/mz.saveUser : param============ ')
      console.log(param)
      showAxiosLoading(true)
      const res = await axios.post('https://mzoin.com/service/mz.saveUser', param, { headers: HEADERS });
      console.log('===========/mz.saveUser : result==============')
      console.log(res)
      return res;
    } catch (error: any) {
      if (error.response && error.response.status) {
        const errorRoute = { name: 'error', query: { errorStatus: error.response.status } }
        router.push(errorRoute)
      } else {
        router.push('/error')
      }
      console.log(error);
    } finally {
    showAxiosLoading(false)
    // commonMethods.showAxiosLoading(false)
    }
  },
  getUserDoList: async (param: object): Promise<any> => {
    try {
      console.log('============/mz.getUserDoList : param============ ')
      console.log(param)
      showAxiosLoading(true)
      const res = await axios.post('https://mzoin.com/service/mz.getUserDoList', param, { headers: HEADERS });
      console.log('===========/mz.getUserDoList : result==============')
      console.log(res)
      return res;
    } catch (error: any) {
      if (error.response && error.response.status) {
        const errorRoute = { name: 'error', query: { errorStatus: error.response.status } }
        router.push(errorRoute)
      } else {
        router.push('/error')
      }
      console.log(error);
    } finally {
    showAxiosLoading(false)
    // commonMethods.showAxiosLoading(false)
    }
  },
  saveSystemSettingTime: async (param: object): Promise<any> => {
    try {
      console.log('============/mz.saveSystemSettingTime : param============ ')
      console.log(param)
      showAxiosLoading(true)
      const res = await axios.post('https://mzoin.com/service/mz.saveSystemSettingTime', param, { headers: HEADERS });
      console.log('===========/mz.saveSystemSettingTime : result==============')
      console.log(res)
      return res;
    } catch (error: any) {
      if (error.response && error.response.status) {
        const errorRoute = { name: 'error', query: { errorStatus: error.response.status } }
        router.push(errorRoute)
      } else {
        router.push('/error')
      }
      console.log(error);
    } finally {
    showAxiosLoading(false)
    // commonMethods.showAxiosLoading(false)
    }
  },
  checkSystemSettingTime: async (param: object): Promise<any> => {
    try {
      console.log('============/mz.checkSystemSettingTime : param============ ')
      console.log(param)
      showAxiosLoading(true)
      const res = await axios.post('https://mzoin.com/service/mz.checkSystemSettingTime', param, { headers: HEADERS });
      console.log('===========/mz.checkSystemSettingTime : result==============')
      console.log(res)
      return res;
    } catch (error: any) {
      if (error.response && error.response.status) {
        const errorRoute = { name: 'error', query: { errorStatus: error.response.status } }
        router.push(errorRoute)
      } else {
        router.push('/error')
      }
      console.log(error);
    } finally {
    showAxiosLoading(false)
    // commonMethods.showAxiosLoading(false)
    }
  },
  getGibuDetail: async (param: object): Promise<any> => {
    try {
      console.log('============/mz.getGibuDetail : param============ ')
      console.log(param)
      showAxiosLoading(true)
      const res = await axios.post('https://mzoin.com/service/mz.getGibuDetail', param, { headers: HEADERS });
      console.log('===========/mz.getGibuDetail : result==============')
      console.log(res)
      return res;
    } catch (error: any) {
      if (error.response && error.response.status) {
        const errorRoute = { name: 'error', query: { errorStatus: error.response.status } }
        router.push(errorRoute)
      } else {
        router.push('/error')
      }
      console.log(error);
    } finally {
    showAxiosLoading(false)
    // commonMethods.showAxiosLoading(false)
    }
  },
}

interface HTTPfn {
  loginCheck(param: object): Promise<any>;
  getUserDetail(param: object): Promise<any>;
  getMogList(param: object): Promise<any>;
  getMogDetail(param: object): Promise<any>;
  saveMog(param: object): Promise<any>;
  editMog(param: object): Promise<any>;
  deleteMog(param: object): Promise<any>;
  saveGibu(param: object): Promise<any>;
  getGibuList(param: object): Promise<any>;
  getMemoList(param: object): Promise<any>;
  saveMemo(param: object): Promise<any>;
  deleteMemo(param: object): Promise<any>;
  saveUser(param: object): Promise<any>,
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $gHTTPfunctions: HTTPfn;
  }
}

export default {
  install (Vue : any) {
    Vue.config.globalProperties.$gHTTPfunctions = HTTPfunctions
    Vue.config.globalProperties.$gShowAxiosLoading = showAxiosLoading
  }
}