const MZInputConst = {
  VALUE_TAG_TYPE_IMAGE: 'G',
  VALUE_TAG_TYPE_INPUT_TEXT: 'I',
  VALUE_TAG_TYPE_TEXTAREA: 'T',
  VALUE_TAG_TYPE_INPUT_NUMBER: 'N',
  VALUE_TAG_TYPE_SELECT: 'S',
  VALUE_TAG_TYPE_SELECT_BUTTON: 'SB',
  VALUE_TAG_TYPE_PASSWORD: 'P',

  VALI_CHECK_TYPE_NOT_NULL: 'NULL',
  VALI_CHECK_TYPE_MAX_SIZE: 'MAX',
  VALI_CHECK_TYPE_MIN_SIZE: 'MIN',
  VALI_CHECK_TYPE_ONLY_NUMBER: 'NUM',
  VALI_CHECK_TYPE_SAME_VALUE: 'T&F',
  VALI_CHECK_TYPE_PER: 'PER'
} as Const

// T&F: true/false
// NULL
// MIN
// MAX

interface Const {
  VALUE_TAG_TYPE_IMAGE: string
  VALUE_TAG_TYPE_INPUT_TEXT: string
  VALUE_TAG_TYPE_TEXTAREA: string
  VALUE_TAG_TYPE_INPUT_NUMBER: string
  VALUE_TAG_TYPE_SELECT: string
  VALUE_TAG_TYPE_SELECT_BUTTON: string
  VALUE_TAG_TYPE_PASSWORD: string

  VALI_CHECK_TYPE_NOT_NULL: string
  VALI_CHECK_TYPE_MAX_SIZE: string
  VALI_CHECK_TYPE_MIN_SIZE: string
  VALI_CHECK_TYPE_ONLY_NUMBER: string
  VALI_CHECK_TYPE_SAME_VALUE: string
  VALI_CHECK_TYPE_PER: string
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $MZInputConst: Const;
  }
}

export default {
  install (Vue : any) {
    Vue.config.globalProperties.$MZInputConst = MZInputConst
  }
}
