import store from '@/store'
interface UserInfo {
  deleteYn?: number;
  encPhone?: string;
  phoneLast?: string;
  soAccessToken?: string;
  soType?: string;
  userPicUrl?: string;
  userDescription?: string;
  userDispName?: string;
  userKey?: number;
  userName?: string;
  tcAccessToken?: string;
  userEmail?: string;
  gibuCount?: any;
}

class AuthData {
  setUserInfo (data: UserInfo) {
    if (data) {
      store.dispatch('MZ_USER/AC_USER', data)
    }

  }
  getUserInfo () {
    return store.getters['MZ_USER/GE_USER']
  }
}

export const authData = new AuthData();