
import { defineComponent } from "vue";

export default defineComponent({
  created () {
    if (this.pThanksDetail) {
        this.value = Number((this.pThanksDetail.gibuAllCost / this.pThanksDetail.mogGoalCost).toFixed(1))*100
    }

  },
  computed: {
    GE_USER () {
      const userInfo = this.$store.getters['MZ_USER/GE_USER']
      return userInfo
    }
  },
  props: {
    pSelectedAddress: String,
    pClosePop: Function,
    pTestSaveMog: Function,
    pThanksDetail: Object,
    pMogData: Object as any,
    pValiInput: Number,
    pListYn: Boolean,
    // pPrice: String
    // pSelected: String
    // pNowPercent: String,
  },
  data() {
    return {
      mPrice: '',
      mConfirmPopShowYn: false,
      value: '0' as number | string,
      mBgPath: [
        require('@/assets/img/example/saeumMug.jpg'),
        require('@/assets/img/example/mankikSticker.jpg'),
      ],
      PopData: {
        closeFn: this.pClosePop,
        size: { w: 900, h: 700 },
        btnList: [
          { btnText: "공유하기", onClickFn: this.createShareLink, btnType: 'noBorder', pointYn: true },
          { btnText: "닫기", onClickFn: this.goGibuList, btnType: 'noBorder', pointYn: false },
        ],
        isFull: true,
        // noHeader: true,
      },
      mOGImage: {
        99: 'https://mzoin.com/resource/images/SU_MUG.png',
        1: 'https://mzoin.com/resource/images/MK_STICKER.jpg',
        100: 'https://mzoin.com/resource/images/CTS_MAIN.png',
        101: 'https://mzoin.com/resource/images/MZ_PUPPY.jpg'
      },
      mConfirmTitle: '확인',
      mConfirmText: ''
    };
  },
  methods: {
    goGibuList () {
      if (this.pClosePop) {
        this.$router.push('/donalist')
        this.pClosePop()
      }
    },
    selectAmount() {
      const price = this.pMogData.mogKey === 99 ? 10000 : 3000;
      const dividedPrice = this.pThanksDetail.gibuCost / price;
      const result = dividedPrice.toLocaleString();
      return result
    },
    openConfirmPop (confirmText: string, confirmTitle: string) {
      this.mConfirmText = confirmText
      this.mConfirmTitle = confirmTitle
      this.mConfirmPopShowYn = true
    },
    closeConfirmPop () {
      this.mConfirmPopShowYn = false
    },
    async createShareLink () {
      var paramMap = new Map()
      paramMap.set('targetKey', this.pMogData.mogKey)
      paramMap.set('title', this.pMogData.title)
      paramMap.set('message', this.pMogData.bodyMinStr)
      paramMap.set('imageLink', this.mOGImage[this.pMogData.mogKey])
      const result = await this.$gHTTPfunctions.createDeepLink(Object.fromEntries(paramMap))
      const link = JSON.parse(result.data.shortLink).shortLink
      if (link) {
        var shareItem = { title: '[모두조인] ' + this.pMogData.title, text: '[모두조인] ' + this.pMogData.title, url: link }
        if (navigator.share) {
            navigator.share(shareItem)
        }  else {
          const textarea = document.createElement('textarea')
          document.body.appendChild(textarea)
          textarea.value = link
          textarea.select()
          document.execCommand('copy')
          document.body.removeChild(textarea)
          if (this.openConfirmPop) {
            this.openConfirmPop('공유 링크가 복사되었습니다.', '링크 복사')
          }
        }
      }
    }
  },
});
