
import { commonValiValue } from '@/utils/MZValidation'
export default {
  props: {
    pSetSearchCondition: Function
  },
  data () {
    return {
      mSearchInput: [{ valiYn: true, eText: '검색어를 입력해주세요', type: 'T', targetName: '페이지', value: null as any, valiBase: [{ type: 'NULL' }] }],
      mConfirmPopShowYn: false,
      mAlertPopInfo: {
        title: '',
        closeFn: this.closeAlertPop,
        size: { w: 300, h: 250 },
        btnList: [{ btnText: "닫기", onClickFn: this.closeAlertPop, btnType: 'noBorder', pointYn: true }],
        isFull: false,
        isConfirm: true,
        noHeader: true
      },
      mConfirmTitle: '알림',
      mConfirmText: ''
    }
  },
  methods: {
    closeConfirmPop () {
      this.mConfirmPopShowYn = false
    },
    openConfirmPop (text: string, title: string) {
      this.mConfirmText = text
      this.mConfirmTitle = title
      this.mConfirmPopShowYn = true
    },
    clearText () {
      this.mSearchInput[0].value = null
    },
    setSearchCondition () {
      const result = commonValiValue(this.mSearchInput)
      if (result.result) {
        const payload = { title: this.mSearchInput[0].value }
        this.pSetSearchCondition(payload)
        this.mSearchInput[0].value = null
      } else {
        this.openConfirmPop(result.resultMsg, '구매내역 검색')
      }
    }
  }
}
