
import { defineComponent } from 'vue'

export default defineComponent({
  data() {
    return {
      mMoveAreaList: [
        { type: 'E', name: '이용약관', clickedYn: false },
        { type: 'G', name: '개인정보 처리방침', clickedYn: false },
        { type: 'B', name: '배송 및 반품 약관', clickedYn: false },
        { type: 'J', name: '전자금융거래 약관', clickedYn: false },
        { type: 'S', name: '제 3자 마케팅 동의', clickedYn: false }
      ]
    }
  },
  methods: {
    goToPolicy (type: string) {
      if (type === 'E') {
        this.$router.push('/policyE')
      } else if (type === 'G') {
        this.$router.push('/policyG')
      } else if (type === 'B') {
        this.$router.push('/policyB')
      } else if (type === 'J') {
        this.$router.push('/policyJ')
      } else if (type === 'S') {
        this.$router.push('/policyS')
      }
    },
  }
})
