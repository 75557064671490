const MZ_DONA = {
  namespace: true,
  state: {
    donaDetail: {}
  },
  getters: {
    GE_DONA_DETAIL (state: any) {
      return state.donaDetail
    }
  },
  mutations: {
    MU_DONA_DETAIL (state : any, payload: any)  {
      state.donaDetail = payload
    }
  },
  actions: {
    AC_DONA_DETAIL ({ commit } :any, payload: object) {
      commit('MU_DONA_DETAIL', payload)
    }
  },
  modules: {
  }
}

export default MZ_DONA