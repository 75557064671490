
import { defineComponent } from 'vue';
import { tokenCookie } from '@/utils/tokenCookie'
import { authData } from '@/utils/authData';

export default defineComponent({
  computed: {
    GE_USER () {
      const savedUserInfo = authData.getUserInfo()
      return savedUserInfo
    }
  },
  props: {
    pOpenNamePop: Function,
    pLoginCheck: Function
  },
  data() {
    return {
      mLoginMenuList: [
        { menuText: '마이페이지', imgPath: require('@/assets/img/common/MZCommonUserIcon.svg'), clickFunction: this.openNamePop },
        { menuText: '로그아웃', imgPath: require('@/assets/img/common/MZCommonLogoutIcon.svg'), clickFunction: this.logout },
      ],
      mLogoutMenuList: [
        { menuText: '로그인', imgPath: require('@/assets/img/common/MZCommonLogoutIcon.svg'), clickFunction: this.loginCheck }
      ],
      mScreenWidth: window.innerWidth,
      mShowMenuYn: false,
      mUserMenuShowYn: false
    };
  },
  methods: {
    goIntroPage () {
      if (this.$route.path !== '/intro') {
        this.$router.push('/intro')
      }
      this.closeMenu()
    },
    openNamePop () {
      this.pOpenNamePop()
      this.mShowMenuYn = false
      this.mUserMenuShowYn = false
    },
    loginCheck () {
      this.pLoginCheck()
      this.mUserMenuShowYn = false
    },
    onResize () {
      this.mScreenWidth = window.innerWidth;
    },
    openMenu () {
      const wrapDom: any = document.querySelector('html')
      wrapDom.style.overflow = 'hidden'
      this.mShowMenuYn = true;
    },
    closeMenu () {
      const wrapDom: any = document.querySelector('html')
      wrapDom.style.overflow = 'auto'
      this.mShowMenuYn = false;
    },
    logout () {
      this.mUserMenuShowYn = false
      tokenCookie.deleteCookie('tcToken');
      this.$store.dispatch('MZ_USER/AC_CLEAR_USER');
      this.$router.go(0);
    },
    goToMainPage () {
      if (this.$route.path !== '/') {
        this.$router.push('/')
        this.closeMenu()
      }
      const wrapDom: any = document.querySelector('html')
      wrapDom.style.overflow = 'auto'
      this.mShowMenuYn = false;
    },
    goToMyGibuList() {
      this.$router.push('/donalist')
      const wrapDom: any = document.querySelector('html')
      wrapDom.style.overflow = 'auto'
      this.mShowMenuYn = false;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },
});
