
import { defineComponent } from 'vue'
import { VueDaumPostcode, VueDaumPostcodeCompleteResult } from 'vue-daum-postcode'

export default defineComponent({
  components: {
    VueDaumPostcode
  },
  props : {
    pSearchJuso: Function as any,
    pClosePop: Function as any
  },
  data () {
    return {
      PopData: {
        closeFn: this.pClosePop,
        size: { w: 600, h: 650 },
        btnList: [
          { btnText: "닫기", onClickFn: this.pClosePop, btnType: 'noBorder', pointYn: false },
        ],
        isFull: true,
      },
      result: {
        value: null
      },
      isOpen: {
        value: false
      },
      selectedAddress: ''
    }
  },
  methods: {
    oncomplete(newResult: VueDaumPostcodeCompleteResult) {
      this.result.value = newResult
      this.isOpen.value = false;
      let resultObject: any = {}
      if (newResult.address) {
        resultObject = {
          zonecode: newResult.zonecode,
          address: newResult.address
        }
      } else {
        resultObject = {}
      }
      this.pClosePop(resultObject)
    }
  }
})
