
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    pFuncLoginCheck: Function,
    pNowPercent: String as any,
    pFuncGetMogDetail: Function,
    pMogDataGibu: Object,
    pMogData: Object,
    pNameCardYn: Boolean,
    pMemoListYn: Boolean
  },
  created () {
    if (this.pMemoListYn) {
      this.listTitle = ['', '내용', '작성일']
    }
  },
  data() {
    return {
      listTitle: ["" ,"내역", "진행기간"],
    };
  },
});
