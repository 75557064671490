import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7aec6af0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "h100P" }
const _hoisted_2 = { class: "MZChangeImgWrap" }
const _hoisted_3 = { class: "MZChangeImgArea" }
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  class: "MZImgForm",
  hidden: "",
  method: "post"
}
const _hoisted_6 = { class: "fl textLeft w100P MZImgDesc" }
const _hoisted_7 = {
  key: 1,
  class: "fl fontBold font14 mTop-05"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gBtn = _resolveComponent("gBtn")!
  const _component_gPopupWrap = _resolveComponent("gPopupWrap")!

  return (_openBlock(), _createBlock(_component_gPopupWrap, {
    pPopInfo: _ctx.mPopData,
    pClosePop: _ctx.pClosePop
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", {
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.imgClickToInput && _ctx.imgClickToInput(...args))),
              class: "cropperImgArea flexAllCenter MZChangeImgBox"
            }, [
              _createElementVNode("img", {
                id: "profileImg",
                ref: "image",
                src: _ctx.mPreviewImgUrl,
                alt: "",
                class: "preview hidden"
              }, null, 8, _hoisted_4)
            ]),
            _createElementVNode("form", _hoisted_5, [
              _createElementVNode("input", {
                class: "formImageFile",
                type: "file",
                title: "선택",
                accept: "image/*",
                ref: "selectFile",
                id: "input-file",
                onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.mHandleImageUpload && _ctx.mHandleImageUpload(...args)))
              }, null, 544)
            ]),
            _createElementVNode("div", _hoisted_6, [
              (_ctx.mCropperYn)
                ? (_openBlock(), _createBlock(_component_gBtn, {
                    key: 0,
                    class: "mTop-05",
                    pBtnInfo: _ctx.mBtnInfo
                  }, null, 8, ["pBtnInfo"]))
                : (_openBlock(), _createElementBlock("p", _hoisted_7, "클릭해서 이미지를 변경할 수 있습니다."))
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["pPopInfo", "pClosePop"]))
}