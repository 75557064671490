import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueDaumPostcode = _resolveComponent("VueDaumPostcode")!
  const _component_gPopupWrap = _resolveComponent("gPopupWrap")!

  return (_openBlock(), _createBlock(_component_gPopupWrap, {
    class: "mTop-30",
    pPopInfo: _ctx.PopData,
    pClosePop: _ctx.pClosePop
  }, {
    default: _withCtx(() => [
      _createVNode(_component_VueDaumPostcode, {
        class: "w100P h100P MZSearchJuso",
        onComplete: _ctx.oncomplete
      }, null, 8, ["onComplete"])
    ]),
    _: 1
  }, 8, ["pPopInfo", "pClosePop"]))
}