import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fe6ce860"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "MZToggleWrap MZShadow" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pBtnList, (btn, index) => {
      return (_openBlock(), _createElementBlock("div", {
        onClick: ($event: any) => (_ctx.changeBtnEvnt(index, btn)),
        class: _normalizeClass(["fl MZToggleBtn commonSubTitleText", _ctx.selectedBtnIndex === index? 'MZPointBtn' : 'MZNormalBtn']),
        key: index,
        style: _normalizeStyle('width: '+ 100 / _ctx.pBtnList.length + '%;')
      }, _toDisplayString(btn.title), 15, _hoisted_2))
    }), 128))
  ]))
}