
import { defineComponent } from "vue";
import { authData } from "@/utils/authData";
import { tokenCookie } from '@/utils/tokenCookie'

import MZCommonHeader from "./components/common/MZCommonHeader.vue";
import MZCommonFooter from "./components/common/MZCommonFooter.vue";
import JOCommonAxiosLoadingPop from '@/components/common/popup/MZCommonAxiosLoadingPop.vue'
import MZNameCardPop from '@/components/popup/MZNameCardPop.vue';

interface UserInfo {
  deleteYn: number;
  encPhone: string;
  phoneLast: string;
  soAccessToken: string;
  soType: string;
  userPicUrl: string;
  userDescription: string;
  userDispName: string;
  userKey: number;
  userName: string;
  tcAccessToken: string;
  userEmail: string;
  loginYn: boolean;
}

interface MogDetailParam {
  mog: {
    mogKey: number,
    reqUserKey?: any
  }
}

interface UserData {
  data: {
    result: boolean,
    user: { userKey: number, soAccessToken: string, tcAccessToken: string }
  }
}

export default defineComponent({
  computed: {
    GE_USER () {
      const savedUserInfo = authData.getUserInfo()
      return savedUserInfo
    },
    GE_SCROLL () {
      const savedScroll = this.$store.getters['MZ_USER/GE_SCROLL']
      return savedScroll
    } 
  },
  components: {
    MZCommonHeader,
    MZCommonFooter,
    JOCommonAxiosLoadingPop,
    MZNameCardPop
  },
  created () {
    if (!this.mAccessToken) {
      this.$store.dispatch('MZ_USER/AC_CLEAR_USER')
    }
  },
  mounted () {
    if (this.GE_SCROLL) {
      setTimeout(() => {
        window.scrollTo({ top: this.GE_SCROLL, behavior: 'auto' })
        this.$store.dispatch('MZ_USER/AC_SCROLL', 0)
      }, 500)
    }
  },
  data() {
    return {
      mMoveCommentYn: false,
      mMogData: Object as any,
      mMogDataGibu: Object as any,
      mBasicUserInfo: Object as any,
			mGetUserDetail: Object as any,
			mUserGibuCount:Object as any,
      mUserInfo: {} as UserInfo,
			mUserDetail: Object as any,
      mAccessToken: tokenCookie.getCookie('tcToken'),
      mConfirmPopShowYn: false,
      mConfirmTitle: '알림',
      mConfirmText: '',
      mShowNameCardYn: false,
      mUserDo: [],
      mLikeCount: 0
    };
  },
  methods: {
    moveToComment() {
      this.mMoveCommentYn = true
    },
    closeNamePop () {
      this.mShowNameCardYn = false
    },
    openNamePop () {
      this.mShowNameCardYn = true
    },
    closeConfirmPop () {
      this.mConfirmPopShowYn = !this.mConfirmPopShowYn
    },
    async funcGetMogDetail(mogDetailKey: number) {
      const param: MogDetailParam = {
        mog: {
          mogKey: mogDetailKey,
        },
      };
      if ( mogDetailKey ) {
        const savedUserInfo: UserInfo = authData.getUserInfo()
        if ( savedUserInfo ) {
          param.mog.reqUserKey = savedUserInfo.userKey
        }
        const result = await this.$gHTTPfunctions.getMogDetail(param);
        if (result && result.data ) {
          this.mMogData = result.data.mog;
          this.mMogDataGibu = result.data.gibuCount[0];
          this.mUserDo = result.data.userDo
          this.mLikeCount = result.data.likeCnt
        }
      }
    },
    async funcGetUserDetail() {
      if (this.GE_USER && this.GE_USER.userKey) {
        const param = { userKey: this.GE_USER.userKey }
        const result = await this.$gHTTPfunctions.getUserDetail(param);
        if (result && result.data) {
          const payload = { gibuCount: result.data.gibuCount[0] }
          authData.setUserInfo(payload)
          this.mUserDetail = result.data;
          this.mUserGibuCount = result.data.gibuCount[0];
          const userInfo = result.data
          authData.setUserInfo(userInfo)
        }
      }
      return
		},
    checkLogin() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line no-undef
      tcSso.loginCheck(null, this.requestLogin)
    },
    async requestLogin (data: any) {
      if (data && data.data && data.data.userMap) {
        const param = {} as UserInfo
        const user = data.data.userMap
        param.userDispName = user.userDispMtext
        param.encPhone = user.encPhone
        param.phoneLast = user.phoneLast
        param.soAccessToken = user.soAccessToken
        param.userPicUrl = user.userImgUrl
        param.soType = user.soType
        param.userEmail = user.userEmail
        param.tcAccessToken = user.tcAccessToken
        const userResult: UserData | any = await this.$gHTTPfunctions.saveUser({ user: param })
        if (userResult.data.result) {
            if (userResult.data.user && userResult.data.user.userKey) {
              this.mUserInfo = userResult.data.user;
              tokenCookie.setCookie('tcToken', this.mUserInfo.tcAccessToken, 1);
              if (this.mUserInfo.userDispName) {
                this.mUserInfo.userDispName = this.mUserInfo.userDispName.split('$^$')[1]
              }
              authData.setUserInfo(this.mUserInfo)
              await this.funcGetUserDetail()
              if (this.GE_USER.certiDate) {
                const html: any = document.querySelector('html')
                if (html) {
                  const scrollLocation = html.scrollTop
                  this.$store.dispatch('MZ_USER/AC_SCROLL', scrollLocation)
                }
                this.$router.go(0)
              } else {
                this.$router.push('/policy')
              }
          }
        } else {
          localStorage.removeItem('user')
          this.$router.go(0)
        }
      }
    },
  },
});
