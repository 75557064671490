
import { defineComponent } from "vue";
import { authData } from "@/utils/authData";

export default defineComponent({
  computed: {
    GE_USER () {
      const savedUserInfo = authData.getUserInfo()
      return savedUserInfo
    }
  },
  props: {
    pClosePop: Function,
    pConfirmText: String,
    pConfirmTitle: String,
    pConfirmBtnList: Object
  },
  data() {
    return {
      mConfirmPopData: {
        title: '알림',
        closeFn: this.pClosePop,
        size: { w: 310, h: 'auto' },
        btnList: [
            { btnType: 'noBorder', btnText: "확인", pointYn: false, onClickFn: this.pClosePop}
        ],
        isFull: false,
        isConfirm: true,
        noHeader: true,
      }
    };
  },
  created() {
    if (this.pConfirmTitle) {
      this.mConfirmPopData.title = this.pConfirmTitle
    }
    if (this.pConfirmBtnList) {
      this.mConfirmPopData.btnList = this.pConfirmBtnList
    }
  },
  methods: {
  },
});
