import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { Store } from 'vuex';
import store from './store';
import httpFn from '@/api/index';
import dateConverter from '@/utils/dateConverter';
import tpCore from '@/assets/ts/MZTpCore'

import MZCommonButton from '@/components/common/MZCommonButton.vue';
import MZCommonButtonList from '@/components/common/MZCommonButtonList.vue';
import MZCommonInput from '@/components/common/MZCommonInput.vue';
import MZLinkShareBtn from '@/components/common/MZLinkShareBtn.vue';
import MZPopLayout from '@/components/common/MZPopLayout.vue';
import MZCommonSubHeader from '@/components/common/MZCommonSubHeader.vue';
import MZInputConst from '@/assets/constants/MZInputConst'
import MZCommonPagenation from '@/components/common/MZCommonPagenation.vue'
import MZCommonSearch from '@/components/common/MZCommonSearch.vue'
import MZCommonSearchItme from '@/components/common/MZCommonSearchItem.vue'
import MZCommonSort from '@/components/common/MZCommonSort.vue'
import MZCommonSelectBtn from '@/components/common/MZCommonSelectBtn.vue'
import MZCommonCofirmPop from './components/common/MZCommonCofirmPop.vue';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import BootstrapVue3 from 'bootstrap-vue-3';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';
const app = createApp(App);

app.use(store).use(router).use(httpFn).use(BootstrapVue3).use(tpCore);
app.use(MZInputConst)

app
  .component('gBtn', MZCommonButton)
  .component('gBtnList', MZCommonButtonList)
  .component('gInput', MZCommonInput)
  .component('gShare', MZLinkShareBtn)
  .component('gPopupWrap', MZPopLayout)
  .component('gSubHeader', MZCommonSubHeader)
  .component('gPagenation', MZCommonPagenation)
  .component('gSearch', MZCommonSearch)
  .component('gSearchItem', MZCommonSearchItme)
  .component('gSort', MZCommonSort)
  .component('VueDatePicker', VueDatePicker)
  .component('gConfirmPop', MZCommonCofirmPop)
  .component('gSelectBtn', MZCommonSelectBtn);

app.config.globalProperties.$store = store;
app.config.globalProperties.$dateConverter = dateConverter;

declare module 'vue' {
  interface ComponentCustomProperties {
    $store: Store<any>;
  }
}

app.mount('#app');
