
import { defineComponent } from "vue";
import { authData } from '@/utils/authData'

import { handleImageUpload } from '@/assets/ts/MZImageCompress'

import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'

import axios from "axios";

export default defineComponent({
  componets: {
  },
  props: {
    pClosePop: Function,
    pMogData: {} as any,
    pFuncGetUserDetail: Function
  },
  data() {
    return {
      mSelectedAddress: {} as any,
      mBtnInfo: { btnText: "다시선택", onClickFn: this.openImgAttach, btnType: 'noBorder', pointYn: true },
      mPopData: {
        title: '프로필 이미지 변경',
        closeFn: this.pClosePop,
        size: { w: 900, h: 700 },
        btnList: [
          { btnText: "저장하기", onClickFn: this.cropAndSave, btnType: 'noBorder', pointYn: true },
          { btnText: "취소", onClickFn: this.pClosePop, btnType: 'noBorder', pointYn: false }
        ],
        isFull: true,
        noHeader: false,
      },
      mImgList: [],
      mPreviewImgUrl: '',
      mCropperYn: false,
      cropper: {} as any
    }
  },
  created () {
    if (this.GE_USER.userPicUrl) {
      this.mPreviewImgUrl = this.GE_USER.userPicUrl
    }
  },
  methods: {
    imgClickToInput () {
      const fileRef:any = this.$refs.selectFile 
      fileRef.value = ''
      if (this.mCropperYn === false) this.$refs.selectFile.click()
    },
    openImgAttach () {
      const fileRef:any = this.$refs.selectFile 
      fileRef.value = ''
      this.$refs.selectFile.click()
    },
    mHandleImageUpload () {
      if (this.$refs.selectFile) {
        const selectFileRef : any = this.$refs.selectFile
        const fileList : any[] = selectFileRef.files
        handleImageUpload(fileList, this.setUploadImg)
      }
    },
    setUploadImg (data : any) {
      this.mImgList[0] = data
      this.refImg = this.$refs.image
      this.mPreviewImgUrl = this.mImgList[0].src
      this.cropper = new Cropper(this.refImg, {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        viewMode: '1',
        dragMode: 'move',
        preview: '.cropperPreviewImg',
        aspectRatio: 1 / 1,
        cropBoxResizable: true,
        wheelZoomRatio: 0.1,
        movable: false
      })
      this.cropper.replace(this.mPreviewImgUrl)
      this.mCropperYn = true
    },
    async cropAndSave () {
      if (!this.mCropperYn) {
        this.pClosePop()
        return
      }
      var cropImg = this.cropper.getCroppedCanvas({ maxWidth: 4096, maxHeight: 4096, imageSmoothingEnabled: false, imageSmoothingQuality: 'high' })
      var dataURL = cropImg.toDataURL('image/png', 0.8)
      // const imgBase64 = previewCanvas.toDataURL('image/png', 0.8)
      const decodImg = atob(dataURL.split(',')[1])
      const array = []
      for (let i = 0; i < decodImg.length; i++) {
        array.push(decodImg.charCodeAt(i))
      }
      const Bfile = new Blob([new Uint8Array(array)], { type: 'image/png' })
      var newSelectFileName = this.mImgList[0].name
      // newSelectFileName = newSelectFileName.replaceAll(' ', '')
      const files = new File([Bfile], newSelectFileName)

      var form = new FormData()
        // var thisthis = this
          // Here we create unique key 'files[i]' in our response dictBase64.decode(data)
          // thisthis.uploadFileList[i].filePath = Base64.decode(thisthis.uploadFileList[i].filePath.replaceAll('data:image/png;base64,', ''))
          form.append('files[0]', files)
          await axios
          // 파일서버 fileServer fileserver FileServer Fileserver
            .post('https://m.passtory.net:7443/fileServer/tp.uploadFile', form,
              {
                headers: {
                  'Content-Type': 'multipart/form-data; charset: UTF-8;'
                }
              })
            .then((res: any) => {
              if (res.data.length > 0) {
                const param = {
                  user: {
                    userKey: this.GE_USER.userKey,
                    userPicUrl: res.data[0].domainPath + res.data[0].pathMtext
                  }
                }
                this.$gHTTPfunctions.saveUser(param)
                authData.setUserInfo({ userPicUrl: res.data[0].domainPath + res.data[0].pathMtext })
                this.pClosePop()
              }
            })
            .catch((error: any) => {
              console.log(error)
            })
      // return files
    }
  },
  computed: {
    GE_USER () {
      const savedUserInfo = authData.getUserInfo()
      return savedUserInfo
    }
  },
});
