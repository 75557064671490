
import { defineComponent } from "vue";

/**
 * props 전달 예시
  {
    title: "임시 제목",
    backRoute: "/",
  },
 */

export default defineComponent({
  props: {
    pHeaderInfo: Object as any,
    pSetSearchCondition: Function,
    pBtnInfo: Object as any,
    pSearchYn: Boolean
  },
  watch: { 
    mSelectedDate: {
      handler (val) {
        if (!val) return
        this.setSearchCondition()
      },
      deep: true
    }
  },
  data () {
    return {
      mSelectedDate: []
    }
  },
  methods: {
    setSearchCondition () {
      const param = {
        gibuFromDateStr: '',
        gibuToDateStr: ''
      }
      const fromDate = `${this.mSelectedDate[0].getFullYear()}-${this.mSelectedDate[0].getMonth() + 1}-${this.mSelectedDate[0].getDate()}`
      param.gibuFromDateStr = fromDate
      if (this.mSelectedDate[1]) {
        const toDate = `${this.mSelectedDate[1].getFullYear()}-${this.mSelectedDate[1].getMonth() + 1}-${this.mSelectedDate[1].getDate()}`
        param.gibuToDateStr = toDate
      } else {
        param.gibuToDateStr = fromDate
      }
      this.pSetSearchCondition(param)
    },
    backBtn() {
      this.$router.push(this.pHeaderInfo.backRoute);
    },
  },
});
