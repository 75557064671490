
import { defineComponent } from 'vue';

/**
 * props 전달 예시
    {
      type: 'S',
      value: '기본 값',
      options?: [
        { opt: '최신 순', value: 'recent' },
        { opt: '오래된 순', value: 'older' },
        { opt: '마감 순', value: 'imminent' },
      ],
      placeholder?: '글을 입력해 주세요',
    }
 */

interface InputInfo {
  type: string;
  value: string;
  option?: [
    {opt: string, value: string}
  ],
  placeholder?: string,
}

export default defineComponent({
  props: {
    pInputInfo: Object as any,
    pClickEvent: Function,
    pEnterEvent: Function
  },
  data() {
    return {
      mInputValue: {} as any,
    }
  },
  methods: {
    clickEvent () {
      if (this.pClickEvent) {
        this.pClickEvent()
      }
    },
    enterEvent () {
      if (this.pEnterEvent) {
        this.pEnterEvent()
      }
    }
  },
  watch: {
    pInputInfo: {
      immediate: true,
      handler (value) {
        if (!value) return
        this.mInputValue = value
      }
    }
  }
})
