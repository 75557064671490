import imageCompression from 'browser-image-compression'
function showAxiosLoading (showYn : boolean) {
  /* alert(showYn) */
  const loadingCompo = document.getElementById('axiosShadow')
  // eslint-disable-next-line no-debugger
  // debugger
  if (showYn) {
    /*  if (loadingCompo.style.display === 'flex') */
    if (loadingCompo) loadingCompo.style.display = 'flex'
  } else {
    if (loadingCompo) loadingCompo.style.display = 'none'
  }
}
async function handleImageUpload (fileList: any[], callback:any) {
  const options = {
    maxSizeMB: 0.5,
    maxWidthOrHeight: 1500,
    useWebWorker: true
  }
  showAxiosLoading(true)
  for (let k = 0; k < fileList.length; k++) {
    const eleFile = fileList[k]

    let fileExt = eleFile.name.substring(
      eleFile.name.lastIndexOf('.') + 1
    )
    // 소문자로 변환
    fileExt = fileExt.toLowerCase()
    if (
      ['jpeg', 'jpg', 'png', 'gif', 'bmp', 'webp', 'svg', 'tiff', 'tif', 'eps', 'heic', 'bpg'].includes(fileExt)
    ) {
      console.log('originalFile instanceof Blob', eleFile instanceof Blob) // true
      console.log(`originalFile size ${eleFile.size / 1024 / 1024} MB`)

      try {
        // eslint-disable-next-line no-undef
        const compressedFile = await imageCompression(eleFile, options)
        console.log('compressedFile instanceof Blob', compressedFile instanceof Blob) // true
        let src = null
        if (compressedFile instanceof Blob) {
          src = await imageCompression.getDataUrlFromFile(compressedFile)
          const decodeImg = atob(src.split(',')[1])
          const array = []
          for (let i = 0; i < decodeImg.length; i++) {
            array.push(decodeImg.charCodeAt(i))
          }
          const Bfile = new Blob([new Uint8Array(array)], { type: 'image/png' })
          const newFile : any = new File([Bfile], compressedFile.name)
          console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`) // smaller than maxSizeMB
          console.log(compressedFile.size)
          console.log(fileList[k].size)
          if (callback) {
            const fileObj = {} as {name: string, size: string, src: string, file: File}
            fileObj.src = src
            fileObj.name = newFile.name
            fileObj.size = newFile.size
            fileObj.file = newFile
            callback(fileObj)
          }
          // if (callback) {
          //   const fileObj = {} as {name: string, size: string, src: string, file: File}
          //   fileObj.src = src
          //   fileObj.name = newFile.name
          //   fileObj.size = newFile.size
          //   fileObj.file = newFile
          //   callback(fileObj)
          // }
          // return newFile
          // this.addFormCard('image', newFile)
        } else {
          src = await imageCompression.getDataUrlFromFile(compressedFile)
        }
        // console.log(`compressedFile preview url: ${src}`) // smaller than maxSizeMB
      } catch (error) {
        console.log(error)
      }
    }
  }
  showAxiosLoading(false)
}

export { handleImageUpload }