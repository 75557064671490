import { createStore } from 'vuex';

import createPersistedState from 'vuex-persistedstate';
import MZ_DONA from './MZDonaStore';
import MZ_USER from './MZUserInfo';
const storageState = createPersistedState({
  paths: ['MZ_DONA', 'MZ_USER'],
  storage: window.localStorage,
  /* key: 'vuexStore',
  storage: window.sessionStorage */
});
const store = createStore({
  modules: {
    // 키: 값 형태로 저장됩니다.
    MZ_DONA: MZ_DONA,
    MZ_USER: MZ_USER
  },
  plugins: [storageState]
});

export default store;
