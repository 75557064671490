
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    pSearchCondition: Object as any,
    pDeleteSearchCondition: Function
  },
  methods: {
    deleteCondition (condition: string) {
      this.pDeleteSearchCondition(condition)
    }
  },
});
