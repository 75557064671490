/* eslint-disable no-undef */
/* eslint-disable no-debugger */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

import router from '@/router';
import store from '@/store';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
webviewBridge
  .registerCallbackFunc(null, 'DB11SZUxZRwQKptk2YEsMA==')
  .then((result: any) => {
    console.log('MKTeamplCore.ts', result);
  });
const isJsonString = (str: any) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export default {
  install(Vue: any) {
    isJsonString
  },
};
