
import { defineComponent } from "vue";
import { authData } from "@/utils/authData";

import MZDonaListTableHeader from '@/components/donalist/MZDonaListTableHeader.vue'
import MZDonaListTable from '@/components/donalist/MZDonaListTable.vue'

export default defineComponent({
  components: {
    MZDonaListTableHeader,
    MZDonaListTable
  },
  props: {
    pGibuList: Array,
    pPageInfo: Object,
    pGetLikeList: Function,
    pCurrentPage: Number,
    pclosePop: Function
  },
  data() {
    return {
    }
  },
  methods: {
    resetPage () {
      const pageRef: any = this.$refs.pagenation
      if (pageRef) {
        pageRef.resetCurrentPage()
      }
    }
  }
})
