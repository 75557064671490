import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "MZCommonMainBody" }
const _hoisted_2 = { class: "MZCommonBodyContent" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JOCommonAxiosLoadingPop = _resolveComponent("JOCommonAxiosLoadingPop")!
  const _component_MZCommonHeader = _resolveComponent("MZCommonHeader")!
  const _component_MZNameCardPop = _resolveComponent("MZNameCardPop")!
  const _component_gConfirmPop = _resolveComponent("gConfirmPop")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_MZCommonFooter = _resolveComponent("MZCommonFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_JOCommonAxiosLoadingPop),
    (_ctx.$route.path !== '/login')
      ? (_openBlock(), _createBlock(_component_MZCommonHeader, {
          key: 0,
          class: "w100P",
          pOpenNamePop: _ctx.openNamePop,
          pLoginCheck: _ctx.checkLogin,
          style: {"position":"fixed","top":"0","left":"0","background-color":"white","z-index":"2"}
        }, null, 8, ["pOpenNamePop", "pLoginCheck"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.mShowNameCardYn)
        ? (_openBlock(), _createBlock(_component_MZNameCardPop, {
            key: 0,
            pclosePop: _ctx.closeNamePop,
            pMoveToComment: _ctx.moveToComment,
            pFuncGetUserDetail: _ctx.funcGetUserDetail,
            pMogData: _ctx.mMogData
          }, null, 8, ["pclosePop", "pMoveToComment", "pFuncGetUserDetail", "pMogData"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        (_ctx.mConfirmPopShowYn)
          ? (_openBlock(), _createBlock(_component_gConfirmPop, {
              key: 0,
              pClosePop: _ctx.closeConfirmPop,
              pConfirmTitle: _ctx.mConfirmTitle,
              pConfirmText: _ctx.mConfirmText
            }, null, 8, ["pClosePop", "pConfirmTitle", "pConfirmText"]))
          : _createCommentVNode("", true),
        (_openBlock(), _createBlock(_component_router_view, {
          key: _ctx.$route.fullPath,
          pLikeCount: _ctx.mLikeCount,
          pUserDo: _ctx.mUserDo,
          pOpenNamePop: _ctx.openNamePop,
          pMogDataGibu: _ctx.mMogDataGibu,
          pMogData: _ctx.mMogData,
          pFuncGetUserDetail: _ctx.funcGetUserDetail,
          pFuncGetMogDetail: _ctx.funcGetMogDetail,
          pFuncLoginCheck: _ctx.checkLogin,
          pMoveToComment: _ctx.mMoveCommentYn
        }, null, 8, ["pLikeCount", "pUserDo", "pOpenNamePop", "pMogDataGibu", "pMogData", "pFuncGetUserDetail", "pFuncGetMogDetail", "pFuncLoginCheck", "pMoveToComment"]))
      ]),
      _createVNode(_component_MZCommonFooter, { class: "mTop-20" })
    ])
  ], 64))
}