
import { defineComponent } from 'vue';

// 넘겨받을 props 형태
/**
  [
    {btnType: string, btnText: string, pointYn: boolean ,onClickFn: Function},
    {btnType: string, btnText: string, pointYn: boolean ,onClickFn: Function},
  ]
*/

export default defineComponent({
  props: {
    pBtnList: Array as any
  },
})
