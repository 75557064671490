interface UserInfo {
  deleteYn: number;
  encPhone: string;
  phoneLast: string;
  soAccessToken: string;
  soType: string;
  userPicUrl: string;
  userDescription: string;
  userDispName: string;
  userKey: string;
  userName: string;
  tcAccessToken: string;
  userEmail: string;
  gibuCount: Array<any>;
  certiDate: string;
}

const MZ_USER = {
	namespaced: true,
  state: {
		userInfo: {
      userKey: ''
    } as UserInfo,
    scrollLocation: 0
  },
	getters: {
		GE_USER (state: any) {
			return state.userInfo
		},
    GE_SCROLL (state: any) {
			return state.scrollLocation
    }
	},
  mutations: {
    MU_USER (state: any, payload: any) {
      if (payload.deleteYn) {
        state.userInfo.deleteYn = payload.deleteYn
      }
      if (payload.encPhone) {
        state.userInfo.encPhone = payload.encPhone
      }
      if (payload.phoneLast) {
        state.userInfo.phoneLast = payload.phoneLast
      }
      if (payload.soAccessToken) {
        state.userInfo.soAccessToken = payload.soAccessToken
      }
      if (payload.userEmail) {
        state.userInfo.userEmail = payload.userEmail
      }
      if (payload.soType) {
        state.userInfo.soType = payload.soType
      }
      if (payload.userDescription) {
        state.userInfo.userDescription = payload.userDescription
      } else {
        state.userInfo.userDescription = undefined
      }
      if (payload.userDispName) {
        state.userInfo.userDispName = payload.userDispName.split('$^$')[1]
      }
      if (payload.userKey) {
        state.userInfo.userKey = payload.userKey
      }
      if (payload.userName) {
        state.userInfo.userName = payload.userName
      }
      if (payload.userJuso) {
        state.userInfo.userJuso = payload.userJuso
      } else {
        state.userInfo.userJuso = undefined
      }
      if (payload.gibuCount) {
        state.userInfo.gibuCount = payload.gibuCount
      }
      if (payload.userDoList) {
        state.userInfo.userDoList = payload.userDoList
      }
      if (payload.certiDate) {
        state.userInfo.certiDate = payload.certiDate
      }
      if (payload.userPicUrl) {
        state.userInfo.userPicUrl = payload.userPicUrl
      }
    },
    MU_CLEAR_USER (state: any) {
      state.userInfo = {}
    },
    MU_SCROLL (state: any, payload: number) {
      state.scrollLocation = payload
    }
  },
	actions: {
    AC_SCROLL ({ commit }: any, payload: number) {
      commit('MU_SCROLL', payload)
    },
		AC_USER ({ commit }: any, payload: object) {
			commit('MU_USER', payload)
		},
    AC_CLEAR_USER ({ commit }: any) {
      commit('MU_CLEAR_USER')
    }
	}
};

export default MZ_USER