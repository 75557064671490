
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    pBtnInfo: Object as any,
    pSetSearchCondition: Function
  },
  data () {
    return {
      mSortDirection: 'ASC'
    }
  },
  watch: {
    pBtnInfo: {
      handler (val) {
        this.mSortDirection = 'ASC'
        this.setSearchCondition()
      },
      deep: true
    },
    mSortDirection: {
      handler (val) {
        this.setSearchCondition()
      }
    }
  },
  methods: {
    setSearchCondition () {
      const param = { orderbyStr: '' }
      if (this.mSortDirection === 'DESC') {
        param.orderbyStr = this.pBtnInfo.value + ' ' + this.mSortDirection
      } else {
        param.orderbyStr = this.pBtnInfo.value
      }
      this.pSetSearchCondition(param)
    },
    setDirection () {
      if (this.mSortDirection === 'ASC') {
        this.mSortDirection = 'DESC'
      } else if (this.mSortDirection === 'DESC') {
        this.mSortDirection = 'ASC'
      }
    }
  },
});
