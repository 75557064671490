<template>
    <div id="axiosShadow" class="JOAxiosLoadingWrap">
        <div class="lds-default">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <!-- <p v-if="this.GE_UPLOAD_PERCENT.percentCompleted !== 100" class="fontBold" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">{{ this.GE_UPLOAD_PERCENT.percentCompleted }}</p> -->
        </div>
        <!-- <div v-if="this.GE_UPLOAD_PERCENT.percentCompleted !== 100" style="width: 150px; height: 10px; background: #fff; border-radius: 20px; position: relative;">
          <div style="height: 10px; background: #0b4180; border-radius: 20px; transition: width 0.1s;" :style="`width: ${this.GE_UPLOAD_PERCENT.percentCompleted}%;`"></div>
        </div> -->
    </div>
</template>
<script>
export default {
  data () {
    return {
      showYn: false
    }
  },
  computed: {
    GE_UPLOAD_PERCENT () {
      return this.$store.getters['JO_JOSA/GE_UPLOAD_PERCENT']
    }
  },
  methods: {
    show () {
      this.showYn = true
      // this.$refs.sSpinner.style.display = 'block'
    },
    hide () {
      this.showYn = false
      // this.$refs.sSpinner.style.display = 'none'
    }
  }
}
</script>

<style>
.JOAxiosLoadingWrap {
    position: fixed;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000026;
    z-index: 100000
}
.lds-default {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
    scale: 1.2;
}
.lds-default div {
    position: absolute;
    width: 6px;
    height: 6px;
    background: #0b4180;
    border-radius: 50%;
    animation: lds-default 1.2s linear infinite;
}
.lds-default div:nth-child(1) {
    animation-delay: 0s;
    top: 29px;
    left: 53px;
}
.lds-default div:nth-child(2) {
    animation-delay: -0.1s;
    top: 18px;
    left: 50px;
}
.lds-default div:nth-child(3) {
    animation-delay: -0.2s;
    top: 9px;
    left: 41px;
}
.lds-default div:nth-child(4) {
    animation-delay: -0.3s;
    top: 6px;
    left: 29px;
}
.lds-default div:nth-child(5) {
    animation-delay: -0.4s;
    top: 9px;
    left: 18px;
}
.lds-default div:nth-child(6) {
    animation-delay: -0.5s;
    top: 18px;
    left: 9px;
}
.lds-default div:nth-child(7) {
    animation-delay: -0.6s;
    top: 29px;
    left: 6px;
}
.lds-default div:nth-child(8) {
    animation-delay: -0.7s;
    top: 41px;
    left: 9px;
}
.lds-default div:nth-child(9) {
    animation-delay: -0.8s;
    top: 50px;
    left: 18px;
}
.lds-default div:nth-child(10) {
    animation-delay: -0.9s;
    top: 53px;
    left: 29px;
}
.lds-default div:nth-child(11) {
    animation-delay: -1s;
    top: 50px;
    left: 41px;
}
.lds-default div:nth-child(12) {
    animation-delay: -1.1s;
    top: 41px;
    left: 50px;
}
@keyframes lds-default {
    0%, 20%, 80%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
}
</style>
