import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/img/common/MZCommonSortArrow.svg'


const _withScopeId = n => (_pushScopeId("data-v-2b96aaa8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "commonSmallText mLeft-10 MZSortWrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gInput = _resolveComponent("gInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_gInput, { pInputInfo: _ctx.pBtnInfo }, null, 8, ["pInputInfo"]),
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.setDirection && _ctx.setDirection(...args))),
      class: "flexAllCenter cursorP"
    }, [
      _createElementVNode("img", {
        src: _imports_0,
        style: _normalizeStyle(_ctx.mSortDirection === 'ASC'? 'transform: rotate(90deg);':'transform: rotate(270deg);'),
        alt: ""
      }, null, 4)
    ])
  ]))
}