import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-30a4994b"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["src"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["src"]
const _hoisted_9 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.pBtnInfo.btnType === 'noBorder' && _ctx.pBtnInfo.pointYn === false)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.pBtnInfo.onClickFn && _ctx.pBtnInfo.onClickFn(...args))),
        class: "MZMainBtnNoPoint commonSubTitleText flexAllCenter cursorP"
      }, [
        (_ctx.pBtnInfo.imgPath)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "mRight-10",
              style: {"width":"18px"},
              src: _ctx.pBtnInfo.imgPath,
              alt: ""
            }, null, 8, _hoisted_1))
          : _createCommentVNode("", true),
        _createElementVNode("p", {
          innerHTML: _ctx.pBtnInfo.btnText
        }, null, 8, _hoisted_2)
      ]))
    : (_ctx.pBtnInfo.btnType === 'noBorder' && _ctx.pBtnInfo.pointYn === true)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.pBtnInfo.onClickFn && _ctx.pBtnInfo.onClickFn(...args))),
          class: "MZMainBtnPoint commonSubTitleText MZBgColor flexAllCenter cursorP"
        }, [
          (_ctx.pBtnInfo.imgPath)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: "mRight-10",
                style: {"width":"18px"},
                src: _ctx.pBtnInfo.imgPath,
                alt: ""
              }, null, 8, _hoisted_3))
            : _createCommentVNode("", true),
          _createElementVNode("p", {
            innerHTML: _ctx.pBtnInfo.btnText
          }, null, 8, _hoisted_4)
        ]))
      : (_ctx.pBtnInfo.btnType === 'border' && _ctx.pBtnInfo.pointYn === false)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.pBtnInfo.onClickFn && _ctx.pBtnInfo.onClickFn(...args))),
            class: "MZSubBtnNoPoint commonNormalText flexAllCenter cursorP"
          }, [
            _createElementVNode("p", {
              innerHTML: _ctx.pBtnInfo.btnText
            }, null, 8, _hoisted_5),
            (_ctx.pBtnInfo.imgPath)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  class: "mLeft-10",
                  style: {"width":"18px"},
                  src: _ctx.pBtnInfo.imgPath,
                  alt: ""
                }, null, 8, _hoisted_6))
              : _createCommentVNode("", true)
          ]))
        : (_ctx.pBtnInfo.btnType === 'border' && _ctx.pBtnInfo.pointYn === true)
          ? (_openBlock(), _createElementBlock("div", {
              key: 3,
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.pBtnInfo.onClickFn && _ctx.pBtnInfo.onClickFn(...args))),
              class: "MZSubBtnPoint commonNormalText flexAllCenter MZBorder2 cursorP"
            }, [
              _createElementVNode("p", {
                innerHTML: _ctx.pBtnInfo.btnText
              }, null, 8, _hoisted_7),
              (_ctx.pBtnInfo.imgPath)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    class: "mLeft-10",
                    style: {"width":"18px"},
                    src: _ctx.pBtnInfo.imgPath,
                    alt: ""
                  }, null, 8, _hoisted_8))
                : _createCommentVNode("", true)
            ]))
          : (_openBlock(), _createElementBlock("div", {
              key: 4,
              class: "MZMainBtn borderAll",
              textContent: _toDisplayString(_ctx.pBtnInfo.btnText),
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.pBtnInfo.onClickFn && _ctx.pBtnInfo.onClickFn(...args)))
            }, null, 8, _hoisted_9))
}