
import { defineComponent } from "vue";
import { authData } from "@/utils/authData";

import MZDonaListTableHeader from '@/components/donalist/MZDonaListTableHeader.vue'
import { Base64 } from 'js-base64';

export default defineComponent({
  components: {
    MZDonaListTableHeader,
  },
  props: {
    pGibuList: Array,
    pPageInfo: Object,
    pGetLikeList: Function,
    pCurrentPage: Number,
    pOpenConfirmPop: Function,
    pclosePop: Function,
    pGetMemoList: Function,
    pMoveToComment: Function
  },
  data() {
    return {
    }
  },
  methods: {
    resetPage () {
      const pageRef: any = this.$refs.pagenation
      if (pageRef) {
        pageRef.resetCurrentPage()
      }
    },
    goDetail (targetKey: number) {
      this.$router.push(`/dona/${targetKey}`)
      this.pMoveToComment()
      this.pclosePop()
    },
    decodeBodyStr(bodyStr: string) {
      return Base64.decode(bodyStr);
    }
  }
})
