import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b658100"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gPopupWrap = _resolveComponent("gPopupWrap")!

  return (_openBlock(), _createBlock(_component_gPopupWrap, { pPopInfo: _ctx.mConfirmPopData }, {
    default: _withCtx(() => [
      _createElementVNode("p", {
        class: "w100P textCenter commonSubTitleText fontBold MZConfirmContents",
        innerHTML: _ctx.pConfirmText
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["pPopInfo"]))
}